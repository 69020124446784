// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import * as R from "ramda";

import FilterItem from "./FilterItem";
import Sort from "./Sort";
import { Filters as StyledFilter, SectionLabel, TernaryButton } from "./styles";
import { getCurrentUserId, getRole } from "src/reducers";

import * as colors from "src/styles/constants/colors";

type Props = {
  clearSort: Function,
  removeSort: Function,
  removeFilter: Function,
  sortColumns: Array<string>,
  filterParams: Object
};

const Filters = ({
  sortColumns,
  clearSort,
  removeSort,
  filterParams,
  removeFilter
}: Props) => {
  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));
  const currentUserRole = useSelector(({ app }) => getRole(app, currentUserId));

  const isAdmin = useMemo(
    () => currentUserRole.includes("admin"),
    [currentUserId, currentUserRole]
  );

  const isFilterApplied = useMemo(
    () => !R.isEmpty(filterParams) && !R.all(R.isEmpty, R.values(filterParams)),
    [filterParams]
  );

  return (
    <StyledFilter>
      {isFilterApplied ? (
        <SectionLabel>{i18n.t(k.FILTERS_APPLIED)}</SectionLabel>
      ) : (
        <SectionLabel style={{ color: colors.grey42 }}>
          {i18n.t(k.NO_FILTERS_APPLIED)}
        </SectionLabel>
      )}

      {/* Applied filter criteria */}
      {R.map(
        (column: string) =>
          R.map((value: string) => {
            return (
              <FilterItem
                key={value}
                column={R.last(column.split("-")) || column}
                fullColumnId={column}
                value={value}
                removeFilter={removeFilter}
              />
            );
          }, filterParams[column] || []),
        R.keys(R.omit(["reportId", "chartId"], filterParams || {}))
      )}

      {!R.isEmpty(sortColumns) && (
        <SectionLabel>{i18n.t(k.SORTED_BY)}</SectionLabel>
      )}

      {/* Applied sorting criteria */}
      <Sort sortParams={sortColumns} removeSort={removeSort} />

      {isFilterApplied && isAdmin && (
        <TernaryButton onClick={clearSort}>{i18n.t(k.CLEAR_ALL)}</TernaryButton>
      )}
    </StyledFilter>
  );
};

export default Filters;
