// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import * as R from "ramda";
import { connect, useSelector, useDispatch } from "react-redux";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";

import useFields from "../useFields";
import SingleFile from "./Single";
import ProgressBar from "./ProgressBar";
import NativeFilePicker from "./NativeFilePicker";
import SharepointFilePicker from "./SharepointFilePicker";
import SourcePicker from "./SourcePicker";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";
import {
  getIsMs365Active,
  getIsMs365AdminActive,
  getChecklistValue,
  getChecklistFieldDetails,
  getWhetherMandatoryField,
  getLockedStatus,
  getRoomFieldValueStatus,
  getFormFieldValueStatus,
  getChecklistFormValue,
  getIsFieldLocked,
  getFormFieldMandatoryStatus,
  getSelectedChecklist,
  getChecklistFileUploadProgress,
  isProcessRowSelected
} from "src/reducers";
import useOAuthIntegration from "src/hooks/useOAuthIntegrationWindow";
import DocumentCreationPopup from "./DocumentCreationPopup";
import { fileCreationMap } from "src/constants/integrations";

import {
  getOneDriveAuthData,
  createMicrosoftDocument
} from "src/api/microsoft365";

import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import { setChecklistValue } from "src/actions/checklist";
import { searchConversationFile, unpinFile } from "src/actions/file";

import { AddButton, MainFilesWrapper } from "../styles";
import type {
  RoomId,
  ChecklistFieldUploadProgress,
  FieldValue,
  FieldId,
  HttpMethods,
  ColumnId
} from "src/types";
import { integrationStatus } from "src/constants";

import { dataStages } from "src/constants";

export const fileMenuStates = Object.freeze({
  idle: null,
  pickSource: "pickSource",
  pickNativeFile: "pickNativeFile"
});

export const fileSources = Object.freeze({
  native: "native",
  local: "local",
  microsoft365: "microsoft365"
});

export type SharepointIntegrationData =
  | {|
      status: typeof integrationStatus.done,
      url: string,
      accessTokens: Object
    |}
  | {|
      status:
        | typeof integrationStatus.userPending
        | typeof integrationStatus.adminPending,
      authorizationUrl: string
    |};

export type SharepointIntegrationState = {
  fetching: boolean,
  error: ?string,
  data: SharepointIntegrationData | null
};

type Props = {
  roomId: RoomId,
  formId?: ?number,
  fieldId: number,
  promptCallback?: ?Function,
  location?: string,
  roomFieldFormId?: ?string,
  fromManageView?: boolean,
  handleClose?: Function,
  _unpinFile: Function,
  selectedFieldValue?: any,
  setSelectedFieldValue?: any => void
};

const File = ({
  roomId,
  formId,
  fieldId,
  _unpinFile,
  location,
  roomFieldFormId,
  fromManageView = false,
  handleClose,
  promptCallback = null,
  setSelectedFieldValue
}: Props) => {
  const dispatch = useDispatch();

  const progress: ChecklistFieldUploadProgress = useSelector(({ app }) =>
    getChecklistFileUploadProgress(app, roomId, fieldId)
  );

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  // $FlowFixMe - optional chaining not yet supported
  const type = details?.get("type");

  const {
    columnId,
    value: selectedValue,
    roomId: selectedRoomId,
    index,
    isViewOnly,
    embeddedIndex
  } = useSelector(({ app }) => getSelectedChecklist(app));
  const originalRoomId = fromManageView ? selectedRoomId : roomId;
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );

  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );

  // Get the correct value depending upon whether the field is rendered
  // on the checklist, inside a form or on the manage view
  const checklistValue = fromManageView
    ? {
        fieldId,
        val: {
          type: type,
          value: (selectedValue
            ? Array.isArray(selectedValue)
              ? selectedValue
              : [selectedValue]
            : []
          ).map(file => file?.name || file),
          checked: true
        },
        value:
          selectedValue && Array.isArray(selectedValue)
            ? selectedValue
            : [selectedValue]
      }
    : formId
      ? formFieldValue
      : checklistFieldValue;

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, roomFieldFormId ?? "")
  );

  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const fieldValueStatus = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, fieldId, originalRoomId)
  );
  const formValueStatus = useSelector(({ app }) =>
    getFormFieldValueStatus(app, fieldId, formId)
  );

  const valueStatus = formId ? formValueStatus : fieldValueStatus;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );

  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const { settings } = useFields({
    // $FlowFixMe
    checklistValue: fromManageView ? selectedValue : checklistValue,
    details
  });

  const preview = settings?.preview ?? false;
  const multiple = settings?.multiple || false;
  const createOptions = settings?.createOptions || {};
  const isMs365Active = useSelector(({ app }) => getIsMs365Active(app));
  const isMs365AdminActive = useSelector(({ app }) =>
    getIsMs365AdminActive(app)
  );

  const uploadOptions = settings?.uploadOptions
    ? settings.uploadOptions
    : isMs365AdminActive
      ? ["unifize", "computer", "sharepoint"]
      : ["unifize", "computer"];

  const updating = valueStatus === dataStages.updating;
  const files = R.reject(R.isNil)(checklistValue?.value || []);
  const fileIds = R.pluck("name")(files);

  const [selectedDocumentType, setDocumentType] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [showDocumentCreationPopup, setDocumentCreationPopup] = useState(false);
  const sharepointPicker = useRef<SharepointFilePicker | null>(null);

  const [menu, setMenu] = useState<$Values<typeof fileMenuStates>>(
    fileMenuStates.idle
  );

  const [sharepointData, setSharepointData] =
    useState<SharepointIntegrationState>({
      fetching: false,
      error: null,
      data: null
    });

  const buttonText =
    (settings || {}).buttonText ||
    (type === "pdf" ? i18n.t(k.GENERATE_PDF) : `+ ${i18n.t(k.ATTACH_FILE)}`);

  const setChecklistValueFromManageView = ({
    id: fieldId,
    value: fieldDetail,
    httpMethod,
    extraBody = null,
    columnId
  }: {
    id: FieldId,
    value: FieldValue,
    httpMethod?: HttpMethods,
    extraBody?: Object,
    columnId?: ColumnId
  }) => {
    const { value, type } = fieldDetail;
    if (rowSelected) {
      if (!multiple) {
        return bulkUpdateProcess({
          attrs: {
            [fieldId]: value
          }
        });
      } else {
        setSelectedFieldValue && setSelectedFieldValue(value);
      }
    } else {
      return updateChecklistFromManageView(
        selectedRoomId,
        {
          [fieldId]: value,
          type,
          value
        },
        index,
        fieldId,
        httpMethod,
        extraBody,
        columnId ?? "",
        embeddedIndex
      );
    }

    if (!multiple && rowSelected && handleClose) {
      handleClose();
    }
  };

  const setChecklistFieldValue = ({
    roomId,
    id,
    value,
    progress,
    formId,
    httpMethod,
    extraBody
  }: {
    roomId: RoomId,
    id: FieldId,
    value: FieldValue,
    progress: boolean,
    formId?: ?number,
    httpMethod?: HttpMethods,
    extraBody?: Object
  }) => {
    if (fromManageView) {
      return setChecklistValueFromManageView({
        columnId,
        extraBody,
        formId,
        httpMethod,
        id,
        progress,
        value
      });
    } else {
      return setChecklistValue({
        roomId,
        id: fieldId,
        value,
        progress: true,
        formId,
        columnId
      });
    }
  };

  const onSelectIntegration = (source: string) => {
    if (sharepointData.fetching) return;
    switch (source) {
      case "sharepoint":
        handleSharepoint();
        break;
      case "word":
      case "powerpoint":
      case "excel":
        handleDocumentCreation(source);
        break;
      default:
        break;
    }
  };

  const handleDocumentCreation = (type: string) => {
    if (!isMs365AdminActive) {
      toast.error(i18n.t(k.MS_ADMIN_NOT_ENABLED));
      return;
    }

    setDocumentType(type);
    setDocumentCreationPopup(true);
  };

  const createDocument = async (name: string) => {
    try {
      setLoading(true);
      const selected: Object = fileCreationMap.get(selectedDocumentType);

      const data = await createMicrosoftDocument({
        fieldId,
        name,
        extension: selected.extension
      });
      setLoading(false);
      setDocumentCreationPopup(false);

      // Save the created document as a checklist file entry
      onDocumentCreate(data);
    } catch (e) {
      console.error(e);
      toast.error(i18n.t(k.UNABLE_TO_CREATE_FILE));
      setLoading(false);
    }
  };

  const onDocumentCreate = async document => {
    const data = {
      ...document,
      source: "microsoft365",
      type: "create"
    };
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: R.concat(fileIds, [data]),
          type,
          checked: true
        },
        progress: true,
        formId,
        columnId
      })
    );

    setMenu(fileMenuStates.idle);
  };

  const handleSharepoint = async () => {
    if (!isMs365Active) {
      toast.error(i18n.t(k.MS_NOT_ENABLED));
      return;
    }

    try {
      setSharepointData({
        fetching: true,
        error: null,
        data: null
      });

      const { url, accessTokens } = await getOneDriveAuthData();

      setSharepointData({
        fetching: false,
        error: null,
        data: {
          status: integrationStatus.done,
          url,
          accessTokens
        }
      });

      if (sharepointPicker.current && sharepointPicker.current.isActive()) {
        sharepointPicker.current.focus();
      } else {
        if (url && accessTokens) {
          sharepointPicker.current = new SharepointFilePicker({
            url: url,
            accessTokens: accessTokens,
            multiple,
            onPick: addSharepointFiles,
            mode: "files"
          });
        }
      }
    } catch (e) {
      if (e.cause?.response?.status == 403) {
        const { authorizationUrl, adminConsent } = e.cause.result;

        setSharepointData({
          fetching: false,
          error: null,
          data: {
            status: adminConsent
              ? integrationStatus.userPending
              : integrationStatus.adminPending,
            authorizationUrl
          }
        });

        if (adminConsent) {
          sharepointIntegrationPopup.start(authorizationUrl);
          setMenu(fileMenuStates.idle);
        }
      } else {
        console.error(e);
        setSharepointData({
          fetching: false,
          error: e.message,
          data: null
        });
      }
    }
  };

  const sharepointIntegrationPopup = useOAuthIntegration({
    app: "microsoft365",
    callback: handleSharepoint
  });

  const generatePdf = useCallback(() => {
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: null,
          type: "pdf",
          checked: true
        },
        progress: true,
        formId,
        columnId
      })
    );
  }, [formId, columnId, setChecklistFieldValue, roomId, fieldId]);

  const handleRemove = useCallback(
    (option: string) => {
      const updatedFileIds = R.without([option])(fileIds);

      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: updatedFileIds,
            type: type,
            checked: true
          },
          progress: true,
          formId,
          columnId
        })
      );
    },
    [formId, roomId, columnId, fieldId, fileIds, setChecklistFieldValue]
  );

  const handleDelete = useCallback(
    (option: string) => {
      handleRemove(option);
      _unpinFile(option);
    },
    [_unpinFile, handleRemove]
  );

  const handleAttachBtnClick = () => {
    if (!locked && type === "file") {
      setMenu(fileMenuStates.pickSource);
    } else if (type === "pdf") {
      generatePdf();
    }
  };

  const handleFileSourceChange = (source: $Values<typeof fileSources>) => {
    if (source === fileSources.native) {
      setMenu(fileMenuStates.pickNativeFile);
    }
  };

  const handleCloseMenu = () => {
    setMenu(fileMenuStates.idle);
    if (sharepointPicker.current) {
      sharepointPicker.current.destroy();
    }
  };

  const handleReOpenSourcePicker = () => {
    setMenu(fileMenuStates.pickSource);
  };

  const handleSelectNativeFile = fileId => {
    if (!fileIds.includes(fileId)) {
      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: R.append(fileId)(fileIds),
            type,
            checked: true
          },
          progress: true,
          formId,
          columnId
        })
      );

      setMenu(fileMenuStates.idle);
    }

    if (promptCallback) promptCallback();
  };

  const addSharepointFiles = files => {
    const newFiles = R.map(R.mergeDeepLeft({ source: "microsoft365" }))(files);
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: R.concat(fileIds, newFiles),
          type,
          checked: true
        },
        progress: true,
        formId,
        columnId
      })
    );

    setMenu(fileMenuStates.idle);

    if (sharepointPicker.current) sharepointPicker.current.destroy();
    if (promptCallback) promptCallback();
  };

  return (
    <MainFilesWrapper>
      {files.map((file, index) => (
        <SingleFile
          roomId={roomId}
          key={index}
          originalName={file.originalName}
          handleRemove={handleRemove}
          handleDelete={handleDelete}
          handleClose={handleClose}
          file={file}
          preview={preview}
          disabled={locked}
        />
      ))}

      {progress &&
        R.keys(progress).map((fileName, index) => (
          <ProgressBar progress={progress[fileName]} key={index} />
        ))}

      <Popover
        placement="bottom-start"
        isOpen={menu !== fileMenuStates.idle}
        onClose={handleCloseMenu}
        strategy="fixed"
      >
        <PopoverTrigger>
          <Trigger
            updating={updating}
            type={type}
            multiple={multiple}
            files={fileIds}
            handleAttachBtnClick={handleAttachBtnClick}
            disabled={locked}
            isMandatory={Boolean(isMandatory)}
            buttonText={buttonText}
            isActive={menu !== fileMenuStates.idle}
            isViewOnly={isViewOnly}
          />
        </PopoverTrigger>

        <PopoverContent
          sx={{
            borderRadius: 4,
            _focus: {
              boxShadow: "none"
            }
          }}
        >
          {menu === fileMenuStates.pickSource && (
            <SourcePicker
              roomId={roomId}
              fieldId={fieldId}
              columnId={columnId}
              formId={formId}
              setChecklistValue={setChecklistFieldValue}
              uploadOptions={uploadOptions}
              files={files}
              location={location}
              multiple={multiple}
              onClose={handleCloseMenu}
              onSelectIntegration={onSelectIntegration}
              onSourceChange={handleFileSourceChange}
              sharepointData={sharepointData}
              promptCallback={promptCallback}
              createOptions={createOptions}
            />
          )}

          {menu === fileMenuStates.pickNativeFile && (
            <NativeFilePicker
              roomId={roomId}
              fileIds={fileIds}
              onSelect={handleSelectNativeFile}
              onBack={handleReOpenSourcePicker}
              onClose={handleCloseMenu}
            />
          )}
        </PopoverContent>
      </Popover>

      {showDocumentCreationPopup && (
        <DocumentCreationPopup
          selected={fileCreationMap.get(selectedDocumentType)}
          isOpen={showDocumentCreationPopup}
          onClose={() => setDocumentCreationPopup(false)}
          onCreate={createDocument}
          isLoading={isLoading}
        />
      )}
    </MainFilesWrapper>
  );
};

export default connect(null, {
  _unpinFile: unpinFile,
  _searchConversationFile: searchConversationFile
})(File);

type TriggerProps = {
  updating: boolean,
  type: "file" | "pdf",
  multiple: boolean,
  files: string[],
  handleAttachBtnClick: Function,
  disabled: boolean,
  isMandatory: boolean,
  buttonText: string,
  isActive: boolean,
  isViewOnly: boolean
};

const Trigger = React.forwardRef(
  (
    {
      updating,
      type,
      multiple,
      files,
      handleAttachBtnClick,
      disabled,
      isMandatory,
      buttonText,
      isActive,
      isViewOnly
    }: TriggerProps,
    ref
  ) => {
    if (updating) return <ButtonLoader />;

    if (!isViewOnly && (type === "pdf" || multiple || R.isEmpty(files)))
      return (
        <AddButton
          data-cy="addFileButton"
          type="button"
          onClick={handleAttachBtnClick}
          disabled={disabled}
          isMandatory={isMandatory}
          ref={ref}
          isActive={isActive}
        >
          {buttonText}
        </AddButton>
      );

    return null;
  }
);
Trigger.displayName == "Trigger";
