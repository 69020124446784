// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Text } from "@chakra-ui/react";
import PinnedListItem from "./PinnedListItem";
import type { AppState } from "src/types";
import {
  deletePinnedList,
  showEditFilterModal,
  setCurrentClickedFilterName
} from "src/actions/filter";
import { clear } from "src/actions/activeFilter";
import { showView } from "src/actions";

type Props = {
  current: Object,
  customPinned: Object,
  view: String,
  searchedPinned: Object,
  emptySearchField: Function,
  clearFilter: Function,
  hideConversation: Function,
  _deletePinnedList: Function,
  _showView: Function,
  _showEditFilterModal: Function,
  _setCurrentClickedFilterName: Function
};

const PinnedList = ({
  current,
  customPinned,
  clearFilter,
  view,
  searchedPinned,
  emptySearchField,
  hideConversation,
  _deletePinnedList,
  _showView,
  _showEditFilterModal,
  _setCurrentClickedFilterName
}: Props) => {
  // deletes custom saved filters
  const deleteCustomPinned = useCallback(
    (e: any, pins: Object) => {
      e.preventDefault();
      e.stopPropagation();
      _deletePinnedList(pins);
      if (view === customPinned.name) {
        clearFilter();
      }
      _showView("My Conversations");
    },
    [view, _deletePinnedList, clearFilter, _showView]
  );

  const editCustomPinned = useCallback(
    (e: any, pinnedList: Object) => {
      e.preventDefault();
      e.stopPropagation();
      _showEditFilterModal();
      _setCurrentClickedFilterName(pinnedList.name);
      hideConversation();
      _showView("My Conversations");
    },
    [
      view,
      clearFilter,
      _showView,
      _setCurrentClickedFilterName,
      hideConversation
    ]
  );

  return (
    <div>
      {searchedPinned?.length === 0 ? null : (
        <Text
          fontSize="xs"
          color="gray.500"
          mb="1"
          pl="4"
          letterSpacing="1.5px"
        >
          {i18n.t(k.SAVED_FILTERS)}
        </Text>
      )}
      {(searchedPinned || []).map(pinnedItem => (
        <PinnedListItem
          name={pinnedItem.name}
          current={current}
          customPinned={pinnedItem}
          type="saved"
          handleDelete={deleteCustomPinned}
          handleEdit={editCustomPinned}
          key={pinnedItem.name}
          emptySearchField={emptySearchField}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  customPinned: app.chatRooms.filters.saved,
  searchedPinned: app.header.searchedPinnedLists,
  view: app.view
});

export default connect(mapStateToProps, {
  _deletePinnedList: deletePinnedList,
  clearFilter: clear,
  _showView: showView,
  _showEditFilterModal: showEditFilterModal,
  _setCurrentClickedFilterName: setCurrentClickedFilterName
})(PinnedList);
