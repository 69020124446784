// @flow
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import k from "src/i18n/keys";
import * as R from "ramda";

import { getAllParticipants, getChatroomMessageFilter } from "src/reducers";
import Icon from "src/icons";
import { active } from "src/styles/constants/colors";

import type { ChatroomMessageFilter } from "src/types";

import {
  MenuContainer,
  FilterMenuContent,
  FilterMenuItem,
  FilterSubmenuItem,
  ToggleIconContainer,
  MenuIconContainer,
  NotifyCircle
} from "./styles";
import UserFilterMenu from "./UserFilterMenu";
import RecordUpdatesMenu from "./RecordUpdatesMenu";

const Filter = () => {
  const [open, setOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState<?number>(null);
  const dispatch = useDispatch();

  const chatroomParticipants = useSelector(({ app }) =>
    R.uniq(getAllParticipants(app))
  );

  const currentFilter: ChatroomMessageFilter = useSelector(({ app }) =>
    getChatroomMessageFilter(app)
  );

  const onClick = (e: Event) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const toggleMenu = (e: Event, index: number) => {
    e.stopPropagation();
    setActiveSubmenu(_ => (_ === index ? null : index));
  };

  return (
    <MenuContainer>
      <FilterMenuItem onClick={onClick} tabIndex={0} onKeyPress={onClick}>
        {currentFilter.users.length || currentFilter.records.length ? (
          <NotifyCircle right="90" bottom={open ? "83" : "50"} />
        ) : null}
        <Icon type="chatFilter" fill={active} />
        <span>{i18n.t(k.FILTER)}</span>
        {open ? (
          <ToggleIconContainer>
            <Icon type="caretUp" />
          </ToggleIconContainer>
        ) : (
          <ToggleIconContainer>
            <Icon type="caretDown" />
          </ToggleIconContainer>
        )}
      </FilterMenuItem>
      {open && (
        <FilterMenuContent>
          <FilterSubmenuItem
            onMouseEnter={e => {
              e.stopPropagation();
              setActiveSubmenu(0);
            }}
            onMouseLeave={e => {
              e.stopPropagation();
              setActiveSubmenu(null);
            }}
            tabIndex={0}
            onClick={e => toggleMenu(e, 0)}
            onKeyDown={e => toggleMenu(e, 0)}
          >
            <MenuIconContainer>
              {currentFilter.users.length ? <NotifyCircle /> : null}
              <Icon type="chatFilterUser" fill={active} />
              {i18n.t(k.USER)}
            </MenuIconContainer>
            <Icon type="chevronRight" />
            {activeSubmenu === 0 && (
              <UserFilterMenu
                dispatch={dispatch}
                participants={chatroomParticipants}
                filteredUsers={currentFilter.users}
              />
            )}
          </FilterSubmenuItem>
          <FilterSubmenuItem
            onMouseEnter={e => {
              e.stopPropagation();
              setActiveSubmenu(1);
            }}
            onMouseLeave={e => {
              e.stopPropagation();
              setActiveSubmenu(null);
            }}
            tabIndex={0}
            onClick={e => toggleMenu(e, 1)}
            onKeyDown={e => toggleMenu(e, 1)}
          >
            <MenuIconContainer>
              {currentFilter.records.length ? (
                <NotifyCircle bottom="18" />
              ) : null}
              <Icon type="chatFilterRecordUpdates" fill={active} />
              {i18n.t(k.CONVERSATIONS)}
            </MenuIconContainer>
            <Icon type="chevronRight" />
            {activeSubmenu === 1 && (
              <RecordUpdatesMenu
                dispatch={dispatch}
                filteredRecords={currentFilter.records}
              />
            )}
          </FilterSubmenuItem>
        </FilterMenuContent>
      )}
    </MenuContainer>
  );
};

export default Filter;
