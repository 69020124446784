// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useSelector, useDispatch } from "react-redux";
import Link from "redux-first-router-link";
import React, {
  useState,
  useLayoutEffect,
  useRef,
  useMemo,
  useEffect
} from "react";
import * as R from "ramda";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import { useDebouncedCallback } from "use-debounce";
import { Text, Flex, Button } from "@chakra-ui/react";

import FormName from "src/components/Form/Name";
import Row from "./Row";
import Loader from "./RowLoader";
import RenderingLoader from "./Loader";
import NoValue from "./NoValue";
import VirtualList from "src/components/VirtualList";
import Filters from "../Filters";
import MenuBar from "../MenuBar";
import HeaderItem from "./HeaderItem";
import ChecklistHeaderItem from "./ChecklistItem";
import SelectAll from "./SelectAll";
import {
  Header as StyledHeader,
  Table as StyledTable,
  TableWrapper,
  EmbeddedFieldsHeader,
  NestedTable,
  FormFieldsHeader,
  Footer,
  PageButton,
  PageIndicatorContainer,
  PageInput,
  Selector
} from "./styles";
import {
  size,
  checklistFieldSizeMap,
  omitedFields,
  manageViewFieldWidthMap
} from "src/constants/processInstanceColumns";
import { dataStages } from "src/constants";
import { getAllEmbeddedFields } from "src/actions/reports";
import {
  getEmbeddedFormColumns,
  getEmbeddedConversationColumns,
  getWorkflowInstances,
  getWorkflowInstanceFilter,
  getPrincipalChecklistFields,
  getProcessTableColumns,
  getReportsTableColumns,
  getExpandedFields,
  getUserNames,
  getTemplateStatus,
  getShowVersionInfo,
  getChecklistFieldsById,
  getFieldSizeMap,
  getWorkflowInstancesById,
  getAllRecords,
  getAreInstancesFormatted,
  getAdditionalFiltersChartId,
  getCurrentUser,
  getWorkflowSettings,
  getBulkUpdateStatus
} from "src/reducers";
import WebWorker from "src/workers/WebWorker";
import worker from "src/workers/app.worker";
import { convertToTitleCase } from "src/utils";
import Search from "../Search";
import { getHeaderColumnSettings } from "./manageViewFilters";
import {
  setManageViewFilter,
  filterEmbeddedFields,
  clearAllRecordsFilter
} from "src/actions/workflows";
import * as colors from "src/styles/constants/colors";
import { setReports } from "src/actions/reports";
import * as styles from "./styles";
import {
  setNewChartDialogAttributes,
  closeNewChartDialog
} from "src/actions/chart";

import type {
  AppState,
  WorkflowInstances,
  WorkflowInstanceFilter,
  UserNames,
  TemplateStatus,
  ManageViewShowVersions,
  ExpandedEmbeddedFields,
  FieldsById,
  FieldSizeMap,
  ProcessTemplateId,
  Action,
  InstancesById,
  AllRecords,
  UnifizeUser,
  Workflow,
  DataStage
} from "src/types";

type Props = {
  reportId: ?string,
  processColumns: Array<Object>,
  reportsColumn: Array<Object>,
  fields: Array<Object>,
  expandedFields: ExpandedEmbeddedFields,
  instances: Array<WorkflowInstances>,
  userNames: Object,
  setManageViewFilter: typeof setManageViewFilter,
  clearAllRecordsFilter: typeof clearAllRecordsFilter,
  filter: WorkflowInstanceFilter,
  templateStatus: ?TemplateStatus,
  showVersions: ManageViewShowVersions,
  embeddedFormColumns: Object,
  embeddedConversationColumns: Object,
  fieldsById: FieldsById,
  fieldSizeMap: FieldSizeMap,
  currentUser: UnifizeUser,
  workflowSettings: $PropertyType<Workflow, "settings">,
  _getAllEmbeddedFields: (templateId: ProcessTemplateId) => Action,
  instancesById: InstancesById,
  _filterEmbeddedFields: typeof filterEmbeddedFields,
  allRecords: AllRecords,
  areInstancesFormatted: boolean,
  bulkUpdateStatus: DataStage
};

const moveRevisionsField = (processColumns: Array<Object>) => {
  const revisionFieldIndex = processColumns.findIndex(
    item => item?.type === "revision"
  );

  if (revisionFieldIndex === -1) return processColumns;

  const titleIndex = processColumns.findIndex(item => item?.key === "title");

  // $FlowFixMe
  return R.move(revisionFieldIndex, titleIndex + 1, processColumns);
};

const Table = ({
  reportId,
  processColumns,
  reportsColumn,
  fields,
  expandedFields,
  instances,
  userNames,
  setManageViewFilter,
  clearAllRecordsFilter,
  _getAllEmbeddedFields,
  filter,
  templateStatus,
  showVersions,
  embeddedFormColumns,
  embeddedConversationColumns,
  fieldsById,
  fieldSizeMap,
  instancesById,
  workflowSettings,
  _filterEmbeddedFields,
  allRecords,
  areInstancesFormatted,
  bulkUpdateStatus
}: Props) => {
  const dispatch = useDispatch();
  const chartId = useSelector(({ app }) => getAdditionalFiltersChartId(app));

  const tableRef = useRef(null);
  const [width, setWidth] = useState("100%");
  const [allRevisionsShown, setRevisionsShown] = useState(false);
  const [columnResizeMode, setColumnResizeMode] = useState("onEnd");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 16
  });

  /**
   * Returns all process instances with an attribute added
   * (`ownerDisplayName`) for the owner's name and excludes cancelled and
   * archived conversations unless user is filtering by including it
   * @param {object[]} instances - all process instances.
   * @param {object} filterParams - current filter's state.
   * @param {object} userNames - UID mapped to `displayName`
   * @param {object} templateStatus - statusId mapped to status data
   */
  const getInstances = (
    instances: Array<WorkflowInstances>,
    filterParams: Object,
    userNames: UserNames,
    templateStatus: ?TemplateStatus
  ) => {
    let allInstances = instances.map((instance, index) => ({
      ...instance,
      ownerDisplayName: userNames[instance.owner || ""]?.displayName || "",
      index
    }));

    // $FlowFixMe
    if (!filterParams?.status?.includes(-3)) {
      allInstances = allInstances.filter(instance => {
        if (instance.status === -3) {
          return false;
        }
        return true;
      });
    }
    // $FlowFixMe
    if (!filterParams?.status?.includes("includeArchived")) {
      allInstances = allInstances.filter(instance => {
        return (
          !instance.status ||
          (typeof instance.status === "number" &&
            templateStatus?.[`${instance.status}`]?.archiveMode !== "archive" &&
            !instance.archived)
        );
      });
    }

    return allInstances;
  };

  // Decide if pagination should be enabled
  const isPaginated = reportId
    ? workflowSettings.isReportPaginated
    : workflowSettings.isProcessTablePaginated;

  const customColumns = useMemo(() => {
    if (reportId) {
      return reportsColumn;
    }
    return processColumns;
  }, [reportId, processColumns, reportsColumn]);

  useEffect(() => {
    if (reportId) {
      _getAllEmbeddedFields(parseInt(filter?.id || "", 10));
    }
  }, [reportId]);

  useLayoutEffect(() => {
    if (tableRef && tableRef.current) {
      setWidth(`${tableRef.current.scrollWidth || "100%"}px`);
    }
  }, [tableRef, fields, customColumns, setWidth]);

  let filteredColumn = moveRevisionsField(
    R.filter(column => {
      return !R.includes(column.type, omitedFields);
    }, customColumns)
  );
  const columnVisibility = R.reduce(
    (columns, column) => ({
      ...columns,
      [column.key || column.id]: column.active
    }),
    {},
    filteredColumn
  );

  const processColumnSize: string = filteredColumn.reduce(
    (accumulator: string, column: Object) => {
      const settings = (() => {
        try {
          return JSON.parse(column.settings || {});
        } catch (error) {
          console.error(error);
          return {};
        }
      })();

      if (column.type && !R.isEmpty(settings)) {
        if (
          !R.isNil(settings) &&
          settings.fields &&
          expandedFields[column.id]
        ) {
          // Embedded fields size
          const embeddedFieldsSize = settings.fields.reduce(accumulator => {
            return `${accumulator} 230px`;
          }, checklistFieldSizeMap[column.type]);

          return `${accumulator} ${embeddedFieldsSize}`;
        }
      }
      return `${accumulator} ${
        column.type ? checklistFieldSizeMap[column.type] : size[column.key]
      }`;
    },
    "36px "
  );

  // Add the expanded form fields & embedded fields as columns to
  // display in the manage view
  filteredColumn = filteredColumn.reduce((acc, column) => {
    if (column.type === "form") {
      return [
        ...acc,
        column,
        ...(embeddedFormColumns[column.id] || []).reduce((acc, column) => {
          if (
            ["conversation", "childConversation", "link"].includes(column.type)
          ) {
            return [
              ...acc,
              column,
              ...(embeddedConversationColumns[column.id] || [])
            ];
          }
          return [...acc, column];
        }, [])
      ];
    } else if (
      ["conversation", "childConversation", "link"].includes(column.type)
    ) {
      const embeddedColumns = embeddedConversationColumns[column.id] || [];
      const allEmbeddedColumns = embeddedColumns.reduce((acc, column) => {
        if (column.type === "form") {
          return [...acc, column, ...(embeddedFormColumns[column.id] || [])];
        }
        return [...acc, column];
      }, []);

      const settings = JSON.parse(
        fieldsById.toJS()[`${column.id}`]?.["settings"] || "{}"
      );

      const showMetaData = settings.showMetaData;

      const isVisible = R.find(
        R.propEq("key", column.id),
        processColumns
      )?.active;

      const isExpanded = expandedFields[`${column.id}`];

      const allColumns = [
        ...acc,
        column,
        ...(showMetaData && isVisible && isExpanded
          ? [
              {
                header: i18n.t(k.OWNER1),
                label: i18n.t(k.OWNER1),
                id: `${column.id}-owner`,
                fieldId: `${column.id}`,
                type: i18n.t(k.OWNER1),
                key: "owner",
                settings: i18n.t(k._4),
                embeddedField: true
              },
              {
                header: i18n.t(k.STATUS1),
                label: i18n.t(k.STATUS1),
                id: `${column.id}-status`,
                fieldId: `${column.id}`,
                type: i18n.t(k.STATUS1),
                key: "status",
                settings: i18n.t(k._4),
                embeddedField: true
              },
              {
                header: i18n.t(k.DUEDATE),
                label: i18n.t(k.DUEDATE),
                id: `${column.id}-dueDate`,
                fieldId: `${column.id}`,
                type: i18n.t(k.DUEDATE),
                key: "dueDate",
                settings: i18n.t(k._4),
                embeddedField: true
              }
            ]
          : []),
        ...(isVisible ? allEmbeddedColumns : [])
      ];

      return allColumns;
    }

    return [...acc, column];
  }, []);

  const columns = React.useMemo(
    () => [
      ...filteredColumn.map(column => {
        const columnId = column.id ?? column.key;
        const columnWidth = reportId
          ? (fieldSizeMap[columnId] ??
            manageViewFieldWidthMap[column.type ?? column.key])
          : manageViewFieldWidthMap[column.type ?? column.key];
        return {
          header:
            typeof column.key === "string"
              ? convertToTitleCase(column.key)
              : column.label,

          ...(column.accessorFn
            ? { accessorFn: column.accessorFn, accessorKey: `${column.id}` }
            : { accessorKey: column.id ? `${column.id}` : column.key }),
          type: column.type,
          settings: column.settings,
          fieldId: column.fieldId || column.id,
          formIndex: column.formIndex,
          formId: column.formId,
          formField: column.formField,
          showTitle: column.showTitle,
          embeddedField: column.embeddedField,
          minSize: 50,
          size: parseInt(
            // $FlowFixMe
            columnWidth?.split("px")[0]
          ),
          ...getHeaderColumnSettings(column.type, column.key, processColumns)
        };
      })
    ],

    [filteredColumn, filter]
  );

  const [allInstances, setAllInstances] = useState([]);
  const sortColumns = filter.sort || [];
  const filterParams = R.omit(
    ["id", "title", "page", "reportId", "chartId", "sort"],
    filter || {}
  );
  const instancesRef = useRef(null);

  const setFilterParams = (filter: Object) => {
    setManageViewFilter({ ...filter, sort: sortColumns });
  };
  const setSortColumns = (sort: Array<string>) => {
    setManageViewFilter({ ...filterParams, sort });
  };

  useEffect(() => {
    if (reportId && areInstancesFormatted && !instancesRef.current) {
      instancesRef.current = true;
      const appliedFilters = R.omit(["deepFilter"], filter);

      const updatedInstances = {};

      let workerData = {
        fieldsById: fieldsById.toJS(),
        instancesById,
        filters: appliedFilters,
        chatroomAttributes: ["owner", "status", "dueDate"],
        updatedInstances,
        allRecords,
        payload: appliedFilters
      };

      const webWorker = new WebWorker(worker);

      // $FlowFixMe
      webWorker.addEventListener("message", event => {
        _filterEmbeddedFields({ instances: event.data });
        setFilterParams(appliedFilters);
      });

      webWorker.postMessage(workerData);
    }
  }, [reportId, instancesById, areInstancesFormatted]);

  useEffect(() => {
    setAllInstances(
      getInstances(instances, filterParams, userNames, templateStatus)
    );
  }, [instances, filter]);

  const data = React.useMemo(() => allInstances, [allInstances]);
  const tableInstance = useReactTable({
    columns,
    data,
    columnResizeMode,
    defaultColumn: {
      size: 50
    },
    state: {
      columnVisibility,
      ...(isPaginated ? { pagination } : {})
    },
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...(isPaginated ? { getPaginationRowModel: getPaginationRowModel() } : {}),
    onPaginationChange: setPagination
  });
  const {
    getHeaderGroups,
    getRowModel,
    setSorting,
    getPrePaginationRowModel,
    setColumnFilters
  } = tableInstance;
  const rows = getRowModel().rows;
  const headerGroups = getHeaderGroups();

  useEffect(() => {
    if (areInstancesFormatted) {
      const filters = R.omit(
        ["id", "page", "reportId", "chartId", "sort"],
        filter || {}
      );
      let filterArr = [];

      for (let filterItem in filters) {
        // Only add filters for visible columns
        if (
          columns.filter(column => column.accessorKey === filterItem).length > 0
        ) {
          filterArr.push({ id: filterItem, value: filters[filterItem] });
        }
      }

      setColumnFilters(filterArr);
      setSorting(
        (filter.sort || []).map(col => ({
          id: col.split(":")[0],
          desc: col.split(":")[1] === "desc"
        }))
      );
    }
  }, [filter, areInstancesFormatted]);

  const renderRow = (index: number) => {
    const row = rows[index];

    if (!row) return;

    const process = row.original;

    if (
      process &&
      bulkUpdateStatus !== dataStages.updating &&
      (row.original.currentVersion ||
        showVersions.get(`${row.original.seqNo}`) ||
        allRevisionsShown)
    ) {
      return (
        <Row
          row={row}
          process={process}
          width={width}
          columnSize={processColumnSize}
          roomId={row.chatroomId}
          index={process.index}
          numberOfColumns={filteredColumn.length + 1}
          processColumns={filteredColumn}
          allRevisionsShown={allRevisionsShown}
        />
      );
    }
  };

  const [debouncedsetFilter] = useDebouncedCallback(
    e =>
      setFilterParams({
        ...filterParams,
        title: e.target.value
      }),
    400
  );

  const setSort = (order: boolean, column: string, toggleSortBy: Function) => {
    const sortColumn = `${column}:${order ? "desc" : "asc"}`;

    const columnIndex = R.indexOf(
      column,
      sortColumns.map(col => col.split(":")[0])
    );
    if (columnIndex !== -1) {
      setSortColumns(R.update(columnIndex, sortColumn, sortColumns));
    } else {
      setSortColumns([...sortColumns, sortColumn]);
    }
    toggleSortBy(order, true);
  };

  // Handle filtering on a column
  const handleFilter = (column, value) => {
    setFilterParams({ ...filterParams, [column]: value });
  };

  // Remove applied filter
  const removeFilter = (column, value) => {
    if (filterParams[column] && filterParams[column].length) {
      const newValue = filterParams[column].filter(val => val !== value);
      setFilterParams({ ...filterParams, [column]: newValue });
    } else {
      setFilterParams(R.omit([column], filterParams));
    }
  };

  // Remove applied sort
  const removeSort = (column: string) => {
    const columnIndex = R.indexOf(
      column.split(":")[0],
      sortColumns.map(col => col.split(":")[0])
    );
    if (columnIndex !== -1) {
      const newSortColummns = R.remove(columnIndex, 1, sortColumns);
      setSortColumns(newSortColummns);
      setSorting(
        newSortColummns.map(col => ({
          id: col.split(":")[0],
          desc: col.split(":")[1] === "desc"
        }))
      );
    }
  };

  const clearSort = () => {
    const webWorker = new WebWorker(worker);

    const updatedInstances = { ...instancesById };

    const workerData = {
      fieldsById: fieldsById.toJS(),
      instancesById,
      filters: filter,
      chatroomAttributes: ["owner", "dueDate", "status"],
      updatedInstances,
      payload: {}
    };

    // $FlowFixMe - Type of event listener is not clear
    webWorker.addEventListener("message", event => {
      _filterEmbeddedFields({ instances: event.data });
    });

    webWorker.postMessage(workerData);
    setSorting([]);
    setManageViewFilter({});
    clearAllRecordsFilter();
  };

  const handleCreateNewChart = () => {
    reportId && dispatch(setReports(reportId));

    dispatch(closeNewChartDialog());

    dispatch(
      setNewChartDialogAttributes({
        show: true
      })
    );
  };

  // Calculate the total number of rows to be displayed for that process template
  const totalRowCount = isPaginated
    ? Object.values(tableInstance.getPaginationRowModel()?.rowsById || {})
        .length
    : rows.length;

  return (
    <>
      <Search onChange={debouncedsetFilter} reportId={reportId} />
      <MenuBar
        rowCount={totalRowCount}
        reportId={reportId}
        getHeaderGroups={getHeaderGroups}
        getRowModel={getPrePaginationRowModel}
        setRevisionsShown={setRevisionsShown}
        allRevisionsShown={allRevisionsShown}
      />

      <Filters
        sortColumns={sortColumns}
        clearSort={clearSort}
        removeSort={removeSort}
        filterParams={filterParams}
        removeFilter={removeFilter}
      />

      <TableWrapper>
        <StyledTable
          style={{
            width: tableInstance.getCenterTotalSize()
          }}
          columnSize={processColumnSize}
          ref={tableRef}
          rules="cols"
        >
          <StyledHeader>
            {isPaginated ? (
              <SelectAll getRowModel={tableInstance.getPaginationRowModel} />
            ) : (
              <Selector />
            )}
            {headerGroups.map((headerGroup, index) => (
              <React.Fragment key={index}>
                {headerGroup.headers.map(header => {
                  const column = header.column.columnDef;

                  return parseInt(column.fieldId) ? (
                    <NestedTable>
                      <thead>
                        <tr>
                          {column.embeddedField ? (
                            <EmbeddedFieldsHeader>
                              {i18n.t(k._24)}
                            </EmbeddedFieldsHeader>
                          ) : null}
                          {column.formField ? (
                            <FormFieldsHeader
                              width={
                                header.getSize() ??
                                checklistFieldSizeMap[column.type]
                              }
                              backgroundColor={
                                column.formIndex % 2 === 0
                                  ? colors.purpleLight
                                  : colors.greenLight
                              }
                            >
                              {column.showTitle ? (
                                <Text
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  margin={0}
                                  maxWidth={header.getSize()}
                                >
                                  <FormName id={column.formId} />
                                  form
                                </Text>
                              ) : null}
                            </FormFieldsHeader>
                          ) : null}
                        </tr>
                        <tr>
                          <ChecklistHeaderItem
                            key={header.id}
                            column={header.id}
                            fieldId={column.fieldId}
                            label={column.header}
                            type={column.type}
                            settings={column.settings}
                            setFilter={handleFilter}
                            filter={filterParams[header.id]}
                            toggleSortBy={(order: boolean) =>
                              setSort(
                                order,
                                header.id,
                                header.column.toggleSorting
                              )
                            }
                            childColumn={
                              column.embeddedField || column.formField
                            }
                            formIndex={column.formIndex}
                            isSortedDesc={column.isSortedDesc}
                            header={header}
                          />
                        </tr>
                      </thead>
                    </NestedTable>
                  ) : (
                    <HeaderItem
                      key={header.id}
                      column={header.id}
                      setFilter={handleFilter}
                      filter={filterParams[header.id]}
                      toggleSortBy={(order: boolean) =>
                        setSort(order, header.id, header.column.toggleSorting)
                      }
                      header={header}
                    />
                  );
                })}
              </React.Fragment>
            ))}
          </StyledHeader>
          <VirtualList
            initialAmount={50}
            progressiveAmount={50}
            role="list"
            renderItem={renderRow}
            renderLoader={() => (
              <RenderingLoader
                columnSize={processColumnSize}
                numberOfColumns={filteredColumn.length + 1}
                loading
              />
            )}
            rowCount={instances.length}
          />

          <Loader
            columnSize={processColumnSize}
            numberOfColumns={filteredColumn.length + 1}
          />

          {instances.length === 0 && <NoValue />}
        </StyledTable>
      </TableWrapper>
      {isPaginated && (
        <Footer>
          <PageButton
            className="border rounded p-1"
            onClick={() => tableInstance.previousPage()}
            disabled={!tableInstance.getCanPreviousPage()}
          >
            {"<"}
          </PageButton>
          <PageButton
            className="border rounded p-1"
            onClick={() => tableInstance.nextPage()}
            disabled={!tableInstance.getCanNextPage()}
          >
            {">"}
          </PageButton>
          <PageIndicatorContainer>
            <div>{i18n.t(k.PAGE)}</div>
            <strong>
              {tableInstance.getState().pagination.pageIndex + 1} {i18n.t(k.OF)}{" "}
              {tableInstance.getPageCount().toLocaleString()}
            </strong>
          </PageIndicatorContainer>
          <PageIndicatorContainer>
            {i18n.t(k.GO_TO_PAGE)}
            <PageInput
              type="number"
              defaultValue={tableInstance.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                tableInstance.setPageIndex(page);
              }}
              className="border p-1 rounded w-16"
            />
          </PageIndicatorContainer>
          <select
            value={tableInstance.getState().pagination.pageSize}
            onChange={e => {
              tableInstance.setPageSize(Number(e.target.value));
            }}
          >
            {[16, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {i18n.t(k.SHOW)} {pageSize}
              </option>
            ))}
          </select>
        </Footer>
      )}

      {chartId && (
        <Flex sx={styles.FooterButtonsWrapper}>
          <Link
            to={reportId && setReports(reportId)}
            style={styles.CancelButton}
          >
            {i18n.t(k.BACK_TO_REPORT)}
          </Link>
          <Button
            onClick={handleCreateNewChart}
            sx={{ borderRadius: "4px" }}
            variant="uPrimary"
          >
            {i18n.t(k.CREATE_ANOTHER_CHART)}
          </Button>
        </Flex>
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const filter = getWorkflowInstanceFilter(app);
  const instances = getWorkflowInstances(app);
  return {
    fields: getPrincipalChecklistFields(app),
    reportId: filter.reportId,
    instancesById: getWorkflowInstancesById(app),
    processColumns: getProcessTableColumns(app, filter.id || ""),
    reportsColumn: getReportsTableColumns(app, filter.reportId || ""),
    expandedFields: getExpandedFields(app),
    instances,
    userNames: getUserNames(app),
    filter,
    templateStatus: getTemplateStatus(app, filter.id || ""),
    showVersions: getShowVersionInfo(app),
    embeddedFormColumns: getEmbeddedFormColumns(app),
    embeddedConversationColumns: getEmbeddedConversationColumns(app),
    fieldsById: getChecklistFieldsById(app),
    fieldSizeMap: getFieldSizeMap(app),
    allRecords: getAllRecords(app),
    areInstancesFormatted: getAreInstancesFormatted(app),
    currentUser: getCurrentUser(app),
    workflowSettings: getWorkflowSettings(app, filter?.id || ""),
    bulkUpdateStatus: getBulkUpdateStatus(app)
  };
};

export default connect(mapStateToProps, {
  setManageViewFilter,
  _getAllEmbeddedFields: getAllEmbeddedFields,
  clearAllRecordsFilter,
  _filterEmbeddedFields: filterEmbeddedFields
})(Table);
