// @flow

import { createSelector } from "reselect";

import { getAllChartIds, getCurrentDashboardCharts } from "src/reducers";

export const getFilteredDashboardCharts = createSelector(
  [getAllChartIds, getCurrentDashboardCharts],
  (allCharts, currentDashboardCharts) => {
    const currentDashboardChartIds = currentDashboardCharts.map(
      chart => `${chart.id}`
    );

    const filteredChartList = allCharts.filter(
      chart => !currentDashboardChartIds.includes(chart.id)
    );

    return filteredChartList;
  }
);
