// @flow
import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import i18n from "i18next";
import k from "src/i18n/keys";

import { useDebouncedCallback } from "use-debounce";

import { getUserNames } from "src/reducers";
import Checkbox from "src/components/Unifize/Checkbox";
import TextInput from "src/components/Unifize/Input/TextInput";
import {
  addUserToMessageFilter,
  addUsersToMessageFilter,
  removeUserFromMessageFilter,
  removeUsersFromMessageFilter
} from "src/actions/chatroom";

import type { UID, UserNames, Action } from "src/types";

import FilterSubmenu from "./FilterSubmenu";
import {
  CheckboxesContainer as UsersContainer,
  UserFilterMenu as StyledUserFilterMenu
} from "./styles";

type Props = {
  dispatch: (action: Action, payload: any) => void,
  participants: Array<UID>,
  filteredUsers: Array<UID>
};

const UserFilterMenu = ({ dispatch, participants, filteredUsers }: Props) => {
  const allUserNames: UserNames = useSelector(({ app }) => getUserNames(app));
  const [users, setUsers] = useState(participants);
  const [searchQuery, setSearchQuery] = useState("");

  const selectedUsers = useMemo(
    () => participants.filter(user => !filteredUsers.includes(user)),
    [filteredUsers]
  );

  const searchUsers = (query: string) => {
    if (query) {
      setUsers(
        participants.filter(participant =>
          // $FlowFixMe
          allUserNames[participant]?.displayName
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      );
    } else if (query?.length === 0 && users.length !== participants.length) {
      setUsers(participants);
    }
  };

  const [debouncedSearchUsers] = useDebouncedCallback(searchUsers, 400);

  return (
    <FilterSubmenu top={33}>
      <StyledUserFilterMenu>
        <TextInput
          value={searchQuery}
          type="text"
          placeholder={i18n.t(k.SEARCH)}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
            e.stopPropagation();
            setSearchQuery(e.target.value);
            debouncedSearchUsers(e.target.value);
          }}
          onKeyDown={(e: Event) => e.stopPropagation()}
          onClick={(e: Event) => e.stopPropagation()}
        />
        <UsersContainer>
          <Checkbox
            label={i18n.t(k.ALL)}
            checked={selectedUsers.length === participants.length}
            onChange={(checked, e) => {
              e && e.stopPropagation();
              if (checked) {
                dispatch(removeUsersFromMessageFilter(participants));
              } else {
                dispatch(addUsersToMessageFilter(participants));
              }
            }}
            indeterminate={
              selectedUsers.length > 0 &&
              selectedUsers.length < participants.length
            }
          />
          {users.map((user, index) => (
            <Checkbox
              key={`userFilterMenu_${index}_${user}`}
              label={allUserNames[user]?.displayName ?? "Unknown"}
              checked={!filteredUsers.includes(user)}
              onChange={(checked, e) => {
                e && e.stopPropagation();
                if (!checked) {
                  dispatch(addUserToMessageFilter(user));
                } else {
                  dispatch(removeUserFromMessageFilter(user));
                }
              }}
            />
          ))}
        </UsersContainer>
      </StyledUserFilterMenu>
    </FilterSubmenu>
  );
};

export default UserFilterMenu;
