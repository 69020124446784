// @flow

import React, { useRef, useEffect, useState } from "react";

import { SubmenuContainer } from "./styles";

const SUBMENU_WIDTH = 206;

const FilterSubmenu = ({
  top,
  children
}: {
  top: number,
  children: React$Node
}) => {
  const [openLeft, setOpenLeft] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    if (menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      if (rect.right + SUBMENU_WIDTH > viewportWidth) {
        setOpenLeft(true);
      }
    }
  }, []);

  return (
    <SubmenuContainer
      ref={menuRef}
      top={`${top}%`}
      openLeft={openLeft}
      widht={SUBMENU_WIDTH}
    >
      {children}
    </SubmenuContainer>
  );
};

export default FilterSubmenu;
