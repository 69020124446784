// @flow

import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Settings as StyledFile,
  FormGroup,
  Label,
  SecondaryLabel,
  InputWrapper
} from "../styles";
import Icon from "src/icons";
import FolderInput from "./FolderInput";
import { useSelector } from "react-redux";
import FileSelect from "../FileSelect";
import Checkbox from "src/components/Checkbox.old";
import Radio from "src/components/Radio";
import { Input } from "src/styles/input";
import type {
  FileFieldSettings,
  FileUploadOptions,
  SharepointFolderAttributes,
  SharepointFileCreateOptions
} from "src/types";
import { VStack, HStack, Box, Tooltip } from "@chakra-ui/react";
import {
  getCurrentUserId,
  getIsMs365AdminActive,
  getIsMs365Active
} from "src/reducers";
import { toast } from "react-toastify";

import { getOneDriveAuthData } from "src/api/microsoft365";
import AuthenticationPopup from "./AuthenticationPopup";
import { integrationStatus } from "src/constants";
import useOAuthIntegration from "src/hooks/useOAuthIntegrationWindow";

import SharepointFilePicker from "src/components/Dock/Checklist/File/SharepointFilePicker";
import type { SharepointIntegrationState } from "src/components/Dock/Checklist/File";

type Props = {
  type: string,
  settings: FileFieldSettings,
  saveSettings: Function
};

const File = ({ settings, saveSettings }: Props) => {
  const [placeholder, setPlaceHolder] = useState("");
  const [multiple, setMultiple] = useState(false);
  const [isDefaultActionDownload, setIsDefaultActionDownload] = useState(true);
  const [preview, setPreview] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [defaultFile, setDefaultFile] = useState([]);
  const [suppressDefaultNotification, setNotifyDefaultValue] = useState(false);
  const [uploadOptions, setUploadOptions] = useState<Array<FileUploadOptions>>(
    []
  );
  const [createOptions, setCreateOptions] =
    useState<SharepointFileCreateOptions>({});
  const [sharepointData, setSharepointData] =
    useState<SharepointIntegrationState>({
      data: null,
      fetching: false,
      error: null
    });
  const [showAuthPopup, setAuthPopup] = useState(false);
  const isMs365Active: boolean = useSelector(({ app }) =>
    getIsMs365Active(app)
  );
  const isMs365AdminActive: boolean = useSelector(({ app }) =>
    getIsMs365AdminActive(app)
  );

  const currentUserUid = useSelector(({ app }) => getCurrentUserId(app));
  const sharepointPicker = useRef<SharepointFilePicker | null>(null);

  useEffect(() => {
    if (settings) {
      setPlaceHolder(settings.placeholder);
      setMultiple(settings.multiple);
      setIsDefaultActionDownload(settings.isDefaultActionDownload ?? true);
      setPreview(settings.preview);
      setSortBy(settings.sortBy || "name");
      setDefaultFile(settings.defaultFile || []);
      setNotifyDefaultValue(settings.suppressDefaultNotification || false);
      setCreateOptions(settings.createOptions ? settings.createOptions : {});
    }
  }, [setPlaceHolder, setMultiple, setIsDefaultActionDownload]);

  useEffect(() => {
    if (settings && settings.uploadOptions) {
      setUploadOptions(settings.uploadOptions);
    } else {
      if (isMs365AdminActive) {
        setUploadOptions(["computer", "unifize", "sharepoint"]);
      } else {
        setUploadOptions(["computer", "unifize"]);
      }
    }
  }, [isMs365AdminActive]);

  useEffect(() => {
    saveSettings({
      placeholder,
      multiple,
      isDefaultActionDownload,
      preview,
      sortBy,
      defaultFile,
      suppressDefaultNotification,
      uploadOptions,
      createOptions
    });
  }, [
    placeholder,
    multiple,
    isDefaultActionDownload,
    preview,
    sortBy,
    defaultFile,
    suppressDefaultNotification,
    uploadOptions,
    createOptions
  ]);

  const handleUploadOptions = option => {
    if (uploadOptions.includes(option)) {
      setUploadOptions(uploadOptions.filter(item => item !== option));
    } else {
      setUploadOptions([...uploadOptions, option]);
    }
  };

  const handleText = useCallback(
    event => {
      setPlaceHolder(event.target.value);
    },
    [setPlaceHolder]
  );

  const handleSort = useCallback(
    (newSort: string) => {
      setSortBy(newSort);
    },
    [setSortBy]
  );

  const handleMultiple = useCallback(
    (multiple: boolean) => {
      setMultiple(multiple);
    },
    [setMultiple]
  );

  const handlePreview = useCallback(
    event => {
      setPreview(event.target.checked);
    },
    [setPreview]
  );

  const handleSuppressDefaultNotification = useCallback(
    event => {
      setNotifyDefaultValue(event.target.checked);
    },
    [setNotifyDefaultValue]
  );

  const handleSharepoint = event => {
    if (event.target.checked) {
      if (!isMs365Active) {
        toast.error("Microsoft 365 not enabled");
        return;
      }
      handleUploadOptions("sharepoint");
    } else {
      handleUploadOptions("sharepoint");
      setCreateOptions({});
    }
  };

  const handleFolderPick = (folder: Array<SharepointFolderAttributes>) => {
    const selected: SharepointFolderAttributes = folder[0];
    const folderDetails: SharepointFileCreateOptions = {
      membersCanEdit: false,
      selector: currentUserUid,
      sharepointFolder: {
        id: selected.id,
        name: selected.name,
        webUrl: selected.webUrl,
        parentReference: selected.parentReference
      }
    };
    setCreateOptions(folderDetails);
    if (sharepointPicker.current) sharepointPicker.current.destroy();
  };

  const handleFolderSelect = useCallback(async () => {
    if (!isMs365Active) {
      toast.error("Microsoft 365 not enabled");
      return;
    }

    try {
      setSharepointData({ fetching: true, error: null, data: null });
      const { url, accessTokens } = await getOneDriveAuthData();
      setSharepointData({
        fetching: false,
        error: null,
        data: { status: integrationStatus.done, url, accessTokens }
      });
      if (url && accessTokens) {
        if (sharepointPicker.current && sharepointPicker.current.isActive()) {
          sharepointPicker.current.focus();
        } else {
          sharepointPicker.current = new SharepointFilePicker({
            url: url,
            accessTokens: accessTokens,
            multiple: false,
            isDefaultActionDownload: true,
            onPick: handleFolderPick,
            mode: "folders"
          });
        }
      }
    } catch (e) {
      if (e.cause?.response?.status == 403) {
        toast.error("User not authenticated");
        const { authorizationUrl, adminConsent } = e.cause.result;
        setSharepointData({
          fetching: false,
          error: null,
          data: {
            status: adminConsent
              ? integrationStatus.userPending
              : integrationStatus.adminPending,
            authorizationUrl
          }
        });
        setAuthPopup(true);
      } else {
        console.error(e);
      }
    }
  });

  const sharepointIntegrationPopup = useOAuthIntegration({
    app: "microsoft365",
    callback: handleFolderSelect
  });

  const handleUserAuthentication = () => {
    if (
      sharepointData.data &&
      sharepointData.data.authorizationUrl &&
      sharepointData.data?.status === integrationStatus.userPending
    ) {
      sharepointIntegrationPopup.start(sharepointData.data.authorizationUrl);
    }
  };

  const setMembersCanEdit = () => {
    setCreateOptions({
      ...createOptions,
      membersCanEdit: !createOptions?.membersCanEdit
    });
  };

  return (
    <StyledFile>
      <FormGroup>
        <Label>Placeholder</Label>
        <InputWrapper>
          <Input
            type="text"
            value={placeholder}
            placeholder="e.g. Upload file"
            onChange={handleText}
            autoFocus
          />
        </InputWrapper>
      </FormGroup>

      <FormGroup>
        <SecondaryLabel>Consumption options:</SecondaryLabel>
        <VStack align="left">
          <Radio
            id="single"
            checked={multiple === false}
            handleChange={() => handleMultiple(false)}
          >
            Allow single
          </Radio>
          <Radio
            id="multiple"
            checked={multiple === true}
            handleChange={() => handleMultiple(true)}
          >
            Allow multiple
          </Radio>
        </VStack>
      </FormGroup>

      <FormGroup>
        <SecondaryLabel>Preview options:</SecondaryLabel>
        <Checkbox handleChange={handlePreview} checked={preview} id="preview">
          Show full preview
        </Checkbox>
      </FormGroup>
      <FormGroup>
        <SecondaryLabel>Default behaviour on manage view:</SecondaryLabel>
        <VStack align="left">
          <Radio
            id="defaultPreview"
            checked={isDefaultActionDownload === false}
            handleChange={() => setIsDefaultActionDownload(false)}
          >
            Preview
          </Radio>
          <Radio
            id="defaultDownload"
            checked={isDefaultActionDownload === true}
            handleChange={() => setIsDefaultActionDownload(true)}
          >
            Download
          </Radio>
        </VStack>
      </FormGroup>

      <FormGroup>
        <SecondaryLabel>Sort by:</SecondaryLabel>
        <VStack align="left">
          <Radio
            id="sortFilesByDate"
            checked={sortBy === "date"}
            handleChange={() => handleSort("date")}
          >
            Date
          </Radio>
          <Radio
            id="sortFilesByName"
            checked={sortBy === "name"}
            handleChange={() => handleSort("name")}
          >
            Name
          </Radio>
        </VStack>
      </FormGroup>

      <FormGroup>
        <SecondaryLabel>Default file:</SecondaryLabel>
        <InputWrapper>
          <FileSelect files={defaultFile} handleFiles={setDefaultFile} />
        </InputWrapper>
      </FormGroup>

      <FormGroup>
        <Checkbox
          handleChange={handleSuppressDefaultNotification}
          checked={suppressDefaultNotification}
          id="suppressDefaultNotification"
        >
          Suppress notifications on default values
        </Checkbox>
      </FormGroup>

      <FormGroup>
        <SecondaryLabel>File upload options:</SecondaryLabel>
        <VStack align="left">
          <Checkbox
            handleChange={() => handleUploadOptions("computer")}
            checked={uploadOptions.includes("computer")}
            id="computer"
          >
            Upload from computer
          </Checkbox>
          <Checkbox
            handleChange={() => handleUploadOptions("unifize")}
            checked={uploadOptions.includes("unifize")}
            id="unifize"
          >
            Unifize file store
          </Checkbox>
          <Checkbox
            handleChange={handleSharepoint}
            checked={uploadOptions.includes("sharepoint")}
            id="sharepoint"
          >
            Office 365 OneDrive / Sharepoint
          </Checkbox>
        </VStack>
      </FormGroup>
      {showAuthPopup && (
        <AuthenticationPopup
          isOpen={showAuthPopup}
          onClose={() => setAuthPopup(false)}
          onAuthenticate={handleUserAuthentication}
        />
      )}
      {uploadOptions.includes("sharepoint") && (
        <Box>
          <HStack>
            <Label>Folder path</Label>
            <Tooltip
              sx={{ maxW: "48rem" }}
              hasArrow
              placement="top-end"
              label="Folder selection is for creation. The folder which get selected will be shared with the member who is creating the file"
            >
              <Box>
                <Icon type="info" height={16} width={16} />
              </Box>
            </Tooltip>
          </HStack>

          <FolderInput
            value={createOptions?.sharepointFolder?.webUrl || ""}
            onClick={handleFolderSelect}
            isLoading={sharepointData.fetching}
          />

          {createOptions?.sharepointFolder?.webUrl && (
            <Checkbox
              handleChange={setMembersCanEdit}
              checked={createOptions?.membersCanEdit}
              id="membersCanEdit"
            >
              The created file can be edited by chatroom members
            </Checkbox>
          )}
        </Box>
      )}
    </StyledFile>
  );
};

export default File;
