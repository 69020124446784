// @flow

import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import { rgba } from "polished";
import { SystemCSSProperties } from "@chakra-ui/react";

import { DeletedMessage } from "src/components/messages/Chat/styles";
import { AssistantTitle as StyledAssistantTitle } from "src/components/messages/Prompt/styles";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as size from "src/styles/constants/size";
import { colors as oldColors } from "src/styles/constants";
import { NameOrEmail as StyledUser } from "src/components/user/styles";
import { HiddenInput } from "src/styles";
import { IconLabel } from "src/styles/box";

export const NotificationContainer = styled.div`
  margin-top: 1.6em;
  font-weight: 400;
`;

export const Common = styled.div`
  position: relative;
  background: ${rgba(colors.primary, 0.1)};
  display: inline-block;
  align-items: center;
  padding: 0.4em 0.8em;
  margin: 0 1.6em;
  border-radius: 0.8em;
  white-space: pre-wrap;
  max-width: 625px;
  > div:first-of-type {
    margin-right: 1.6em;
  }
`;

export const Comment = styled.div`
  display: flex;
  background: ${colors.brightLight};
  padding: 0.6em;
  margin: 0.4em 0 0.4em 0 !important;
  border-radius: 0.4em;
`;

export const FileOrEmailContainer = styled.div`
  position: relative;
  margin-top: 1.6em;
  padding: 0 0.8em;
  display: flex;
  > div {
    margin-top: 0;
    > div:nth-of-type(2) {
      max-width: calc(100% - 50px);
    }
  }
`;

export const FileOrEmailContent = styled.div`
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  background: ${ifProp(
    "isCurrentUser",
    colors.currentUserHighlight,
    colors.grey0
  )};
  margin-bottom: 1em;
  min-width: ${size.minChatWidth};
`;

export const EmailHeader = styled.div`
  display: flex;
`;

export const FileOrEmailInfo = styled.div`
  color: ${colors.secondary};
  font-size: 0.75rem;
`;

export const Comments = styled.div`
  margin: ${spacing.space_inset_default};
  cursor: text;
  max-width: 548px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  background: ${ifProp(
    "isCurrentUser",
    colors.currentUserHighlight,
    colors.grey0
  )};
  margin-bottom: 2em;
  cursor: pointer;
`;

export const FileContent = styled(Content)`
  flex-direction: column;
  min-width: 200px;
`;

export const FilePreviewContainer = styled.div`
  > div {
    max-width: 548px;
    max-height: 548px;
  }
  > div > img {
    max-width: 100%;
    margin: 0;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > div:first-of-type {
    font-weight: bold;
  }
`;

export const FileDetails = styled.div`
  display: flex;
  margin-left: 0.8em;
  margin-top: 1em;
  align-items: center;
  > div {
    margin-left: 0.8em;
  }
`;

export const HiddenImage = styled.img`
  width: 0px;
  height: 0px;
`;

export const EmailContainer = styled.div`
  background: ${colors.grey0};
  display: flex;
  padding: 0.8em;
`;

export const EmailIconContainer = styled.div`
  width: 34px;
`;

export const EmailContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h4`
  margin: 0;
`;

export const EmailAddresses = styled.div`
  font-size: 11px;
  color: ${colors.grey5};
`;

export const EmailBody = styled.div`
  position: relative;
  margin-top: 0.5em;
  background: ${colors.brightLight};
  border-radius: 0.2em;
  padding: 0.4em;
  white-space: normal;

  > div:first-of-type {
    line-height: 1.5em;
    overflow-y: hidden;
    overflow-x: auto;
    max-height: ${ifProp({ expanded: false }, "25rem", "auto")};
    width: 100%;
    font-size: 13px;
    margin: 0;
  }
`;

export const Expand = styled.div`
  color: ${colors.active};
  background-color: "transparent";
  font-size: 13px;
  padding-top: 0.5em;
  font-weight: bold;
  line-height: 1.31;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  left: 1.2em;
  overflow: hidden;
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const FileUpload = styled(HiddenInput)`
  div {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
  }
`;

export const Files = styled(IconLabel)`
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  > a {
    margin: ${spacing.space_s};
    color: ${colors.grey5};

    > span {
      line-height: 1.6;
      font-size: 1em;
    }
  }
`;

export const Download = styled.div`
  display: block;
  text-decoration: none;
  position: absolute;
  height: 40px;
  width: 40px;
  top: -40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Status = styled.span`
  border-radius: 0.4em;
  padding: 0.2em 0.6em;
  margin-left: 0.8em;
  border: 1px solid;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Complete = styled(Status)`
  border-color: ${colors.success};
  background: ${colors.currentUser};
  color: ${colors.success};
`;

export const Cancel = styled(Status)`
  border-color: ${colors.secondary};
  background: ${colors.secondary};
  color: ${colors.brightLight};
`;

export const Due = styled(Status)`
  border-color: ${colors.secondary};
  color: ${colors.secondary};
`;

export const UserName = styled.span`
  color: ${props => props.hashColor};
  span {
    color: ${props => props.hashColor};
  }
`;

export const ChecklistNotification = styled.div`
  padding: 0 0.8em;
  position: relative;
`;

export const ChecklistInfo = styled.div`
  font-size: 12px;
  color: ${colors.secondary};
  span {
    cursor: pointer;
    font-weight: 700;
    color: ${colors.active};
  }
`;

export const ChecklistContent = styled.div`
  margin-top: 0.2em;
  padding: 0.4em 0.8em;
  margin-bottom: 1em;
  background: ${colors.grey0};
  border-radius: 0.2em;
  max-width: ${size.maxChatWidth};
`;

export const ChecklistField = styled.div`
  display: flex;
  input,
  label {
    cursor: default !important;
  }
`;

export const ChecklistBody = styled.div`
  padding-top: 0.2em;
  margin-left: 0.3em;
  display: flex;
  flex-direction: column;
  width: 95%;
  > p {
    margin: 0px;
  }

  /* Change the font size of user */
  ${StyledUser} {
    font-size: 0.88rem;
    font-weight: normal;
  }
`;

export const NewValue = styled.span`
  display: block;
  margin-top: 0.2em;
  word-break: break-word;
  white-space: pre-line;
  > span {
    font-weight: initial;
  }
  > div > a > span > span {
    display: initial;
  }
`;

export const OldValue = styled(NewValue)`
  margin-top: 0.2em;
  color: ${oldColors.disabledBorderGrey};
  text-decoration: line-through;
  > span {
    text-decoration: line-through;
  }
  > div > a > span > span {
    text-decoration: line-through;
  }
`;

export const ValueList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StartComment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div:first-of-type {
    font-size: 0.88rem;
  }
`;

export const FileRemove = styled(DeletedMessage)``;

export const FileWrapper = styled.div`
  background-color: ${colors.brightLight};
  display: flex;
  cursor: pointer;
  width: 227px;
`;

export const FileIconWrapper = styled.div`
  padding: ${spacing.space_xs};
  display: flex;
  align-items: center;
  > svg {
    width: 32px;
    height: 37px;
  }
`;

export const FileSize = styled.div`
  font-size: 10px
  line-height: 12px;
  color: ${colors.secondary};
`;

export const Name = styled.span`
  font-size: 12px;
  line-height: 1.25rem;
  width: 12.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ToogleText = styled.span`
  font-size: 12px;
  line-height: 1rem;
  color: ${colors.blue};
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 0.3px;
  margin-right: 7em;
`;

export const MutedToogleText = styled(ToogleText)`
  cursor: initial;
  color: ${colors.inkLightest};
`;

export const AssistantTitle = styled(StyledAssistantTitle)`
  color: ${colors.blue};
  line-height: 20px;
`;

export const MessageText = styled.div`
 margin: 0.30em 0em;

 > span {
   line-height: 1rem;
   > p:empty {
    display: none;
   }
 }
 > span:nth-of-type(1) {
   margin-left: 0em;
   > p:empty {
    display: none;
   }
 }
  > span > p {
    margin: 0em;
    margin-right: 0.20em;
    display: inline-block;
  };
  > span {
    > span  {
      font-weight: bold;
      > span {
        font-weight: bold;
      }
    }
  }
 
  }
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5em;
  cursor: pointer;
  > svg {
    cursor: pointer;
  }
`;

export const Label = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  font-size: 12px;
  color: ${colors.inkLight};
`;

export const Modal: SystemCSSProperties = {
  overflowY: "scroll"
};

export const ModalHeader: SystemCSSProperties = {
  position: "sticky",
  top: "0",
  width: "100%",
  bg: colors.brightLight,
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

export const GroupMembersModalTitle: SystemCSSProperties = {
  color: colors.grey29,
  textTransform: "uppercase",
  fontSize: "10px",
  fontWeight: 500,
  lineHeight: "16px",
  letterSpacing: "1.5px",
  whiteSpace: "nowrap",
  minWidth: "280px",
  maxWidth: "280px",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

export const GroupMemberCard: SystemCSSProperties = {
  display: "flex",
  alignItems: "center",
  my: 3
};

export const GroupModalCloseButton: SystemCSSProperties = {
  background: "white",
  outline: "none",
  border: "none",
  "*": {
    cursor: "pointer"
  }
};

export const MemberAvatar: SystemCSSProperties = {
  bg: colors.unifizeBlue,
  color: colors.brightLight,

  borderRadius: "4px",
  "& img": {
    borderRadius: "3px"
  }
};

export const MemberNameWrapper: SystemCSSProperties = {
  ml: 4,
  flexDirection: "column"
};

export const MemberName: SystemCSSProperties = {
  maxWidth: "9rem",
  my: 0,
  fontSize: "xs",
  lineHeight: "1rem"
};

export const MemberEmail: SystemCSSProperties = {
  my: 0,
  fontSize: "10px",
  lineHeight: "0.75rem",
  color: colors.grey5
};

export const GroupTitle: SystemCSSProperties = {
  color: colors.blue500,
  cursor: "pointer",
  textDecoration: "underline"
};
