// @flow
import styled from "styled-components";

import * as colors from "src/styles/constants/colors";

export const MenuContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const FilterMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FilterMenuItem = styled.span`
  display: inline-flex;
  cursor: pointer;
  width: 100%;
  height: 2.5rem;
  padding: 1rem;
  align-items: center;
  gap: 0.5rem;
  &:hover,
  &:focus {
    background: ${colors.grey32};
  }
`;

export const FilterSubmenuItem = styled.span`
  display: inline-flex;
  cursor: pointer;
  width: 100%;
  height: 2.5rem;
  align-items: center;
  padding: 0 1rem 0 2rem;
  justify-content: space-between;
  &:hover,
  &:focus {
    background: rgba(133, 133, 133, 0.05);
  }
`;

export const MenuIconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

export const ToggleIconContainer = styled.div`
  margin-left: auto !important;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    border-radius: 100px;
    background: ${colors.skyDark};
  }
`;

export const SubmenuContainer = styled.div`
  position: absolute;
  ${props => (props.openLeft ? "right: 100%;" : "left: 100%;")}
  top: ${props => (props.top ? props.top : "0")};
  width: ${props => (props.width ? props.width : "206")}px;
  background-color: ${colors.brightLight};
  border: 1px solid ${colors.skyLighter};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  margin: 0;
  padding: 0.5rem;
  z-index: 100;
`;

export const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const UserFilterMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const NotifyCircle = styled.span`
  position: absolute;
  border-radius: 100%;
  background: red;
  right: ${props => props.right || "85"}%;
  bottom: ${props => props.bottom || "51"}%;
  padding: 4px;
`;
