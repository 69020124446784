// @flow

import { combineReducers } from "redux";
import { OrderedSet, Map } from "immutable";
import { createSelector } from "reselect";
import * as R from "ramda";

import * as atypes from "src/constants/actionTypes";
import {
  messageActionTypes,
  defaultChatroomFilter
} from "src/constants/chatroom";
import { getChecklistFieldType } from "src/reducers/checklist";

import type {
  Action,
  AppState,
  RecentChats,
  RecentChatsByRoom,
  RecentChatsById,
  LastRead,
  RecentChatsFilter,
  Syncing,
  ChatroomMessageFilter,
  UnifizeChat
} from "src/types";

/**
 * Extract room ID from normalized action payload
 * @param  {Object} payload
 * @return {string} - room name
 */
const getRoomId = (payload: Object) =>
  payload.entities.message[payload.result].roomId;

const lastRead = (state: LastRead = Map(), { type, payload }) => {
  switch (type) {
    case atypes.SYNC_USER_CHATROOM_ATTRIBUTES_SUCCESS:
      if (state.hashCode() !== Map(payload.lastRead).hashCode()) {
        return state.merge(Map(payload.lastRead));
      }
      return state;
    default:
      return state;
  }
};

const byRoom = (
  state: RecentChatsByRoom | {} = {},
  { type, payload }: Action
): RecentChatsByRoom => {
  switch (type) {
    case atypes.NEW_MESSAGES:
      return {
        ...state,
        [payload.roomId]: (state[payload.roomId] || new OrderedSet()).union(
          payload.messages.result
        )
      };
    case atypes.NEW_MESSAGE:
      return {
        ...state,
        // Append ids to their respective rooms
        [getRoomId(payload)]: (
          state[getRoomId(payload)] || new OrderedSet()
        ).add(payload.result)
      };
    case atypes.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        [payload.roomId]: state[payload.roomId].delete(payload.id)
      };
    case atypes.FETCH_MESSAGE_RANGE_SUCCESS:
      return {
        ...state,
        [payload.roomId]: (state[payload.roomId] || new OrderedSet()).union(
          Object.keys(payload.messages)
        )
      };
    default:
      return state;
  }
};

const byId = (
  state: RecentChatsById | {} = {},
  { type, payload }: Action
): RecentChatsById => {
  switch (type) {
    case atypes.NEW_MESSAGES:
      return {
        ...state,
        ...payload.messages.entities
      };
    case atypes.NEW_MESSAGE:
      return {
        ...state,
        ...payload.entities.message
      };
    case atypes.FETCH_MESSAGE_SUCCESS:
      return {
        ...state,
        [payload.id]: payload.message
      };
    case atypes.FETCH_MESSAGE_RANGE_SUCCESS:
      return { ...state, ...payload.messages };
    case atypes.DELETE_MESSAGE_SUCCESS:
      const { [payload.id]: omit, ...rest } = state;
      return { ...rest };
    default:
      return state;
  }
};

const showLoading = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_LOADING_CHATROOM:
      return true;
    case atypes.HIDE_LOADING_CHATROOM:
      return false;
    default:
      return state;
  }
};

const initialValue = {
  messages: [],
  id: ""
};

const filter = (state: RecentChatsFilter = initialValue, { type, payload }) => {
  switch (type) {
    case atypes.SET_RECENT_CHAT_FILTER:
      return payload;
    case atypes.CLEAR_RECENT_CHAT_FILTER:
    case atypes.TOGGLE_RECENT_CHAT_FILTER:
      if (R.isEmpty(state.messages) && payload.messages !== undefined) {
        return payload;
      }
      return initialValue;
    default:
      return state;
  }
};

const newMessage = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.INSERT_CHAT_SUCCESS:
    case atypes.HAS_NEW_MESSAGE:
      return true;
    case atypes.RESET_HAS_NEW_MESSAGE:
      return false;
    default:
      return state;
  }
};

const clearInput = (state: boolean = false, { type }) => {
  switch (type) {
    case atypes.TRIGGER_CLEAR_INPUT:
      return true;
    case atypes.RESET_CLEAR_INPUT:
      return false;
    default:
      return state;
  }
};

const syncing = (state: Syncing = {}, { type, payload }) => {
  switch (type) {
    case atypes.START_MESSAGE_SYNC:
      return {
        ...state,
        [payload.room]: true
      };
    default:
      return state;
  }
};

const messageFilter = (
  state: ChatroomMessageFilter = defaultChatroomFilter,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CHATROOM_MESSAGE_FILTER:
      return {
        records: payload.records || [],
        users: payload.users || [],
        names: payload?.names || []
      };
    case atypes.ADD_USER_TO_CHATROOM_FILTER:
      return state.users.includes(payload)
        ? state
        : { ...state, users: [...state.users, payload] };
    case atypes.REMOVE_USER_FROM_CHATROOM_FILTER:
      return { ...state, users: state.users.filter(user => user !== payload) };
    case atypes.ADD_USERS_TO_CHATROOM_FILTER:
      return { ...state, users: R.union(state.users, payload) };
    case atypes.REMOVE_USERS_FROM_CHATROOM_FILTER:
      return {
        ...state,
        users: state.users.filter(user => !payload.includes(user))
      };
    case atypes.ADD_RECORD_UPDATE_TO_CHATROOM_FILTER:
      return state.records.includes(payload)
        ? state
        : { ...state, records: [...state.records, payload] };
    case atypes.REMOVE_RECORD_UPDATE_FROM_CHATROOM_FILTER:
      return {
        ...state,
        records: state.records.filter(recordUpdate => recordUpdate !== payload)
      };
    default:
      return state;
  }
};

const recentChats = combineReducers<Object, Action>({
  byRoom,
  byId,
  lastRead,
  showLoading,
  filter,
  newMessage,
  clearInput,
  syncing,
  messageFilter
});

export default recentChats;

export const getLastReadTime = (state: RecentChats, roomId: string) =>
  state.lastRead.get(roomId);

const getByRoom = (state: RecentChats) => state.byRoom;
const getMemoizedByRoom = createSelector([getByRoom], byRoom => byRoom);

const getProvidedRoomId = (state: RecentChats, roomId: string) => roomId;
const getMemoizedProvidedRoomId = createSelector(
  getProvidedRoomId,
  roomId => roomId
);

export const getChatIdsByRoom = createSelector(
  [getMemoizedByRoom, getMemoizedProvidedRoomId],
  (chatIds, roomId) => {
    return chatIds[roomId] || new OrderedSet();
  }
);

export const getChatsById = (state: RecentChats) => state.byId;

export const getMessageFilter = (state: RecentChats) =>
  state?.messageFilter || { records: [], users: [] };

export const isMessageFilterActive = (state: RecentChats) =>
  (state?.messageFilter?.users.length ?? 0) !== 0 ||
  (state?.messageFilter?.records.length ?? 0) !== 0;

export const getRecentChats = createSelector(
  [getChatIdsByRoom, getChatsById],
  (ids, chats) => {
    const data: Array<UnifizeChat> = ids.map(id => chats[id]).toArray();
    return R.sortBy(R.prop("timestamp"))(data);
  }
);

export const getFilteredRecentChats = createSelector(
  [
    (state: AppState, roomId) => getChatIdsByRoom(state.recentChats, roomId),
    (state: AppState) => getChatsById(state.recentChats),
    (state: AppState) => getMessageFilter(state.recentChats),
    (state: AppState) => fieldId =>
      getChecklistFieldType(fieldId)(state.checklist)
  ],
  (ids, chats, messageFilter: ChatroomMessageFilter, getFieldTypeSelector) => {
    const data: Array<UnifizeChat> = ids.map(id => chats[id]).toArray();
    const activeRecordFilters = messageFilter.records.length
      ? messageFilter.records.reduce((acc, filter) => {
          if (messageActionTypes[filter]?.length) {
            acc.push(...messageActionTypes[filter]);
          }
          return acc;
        }, [])
      : [];

    const filteredData =
      messageFilter.users.length || messageFilter.records.length
        ? data.filter(chat => {
            let type = "";
            if (
              chat.action &&
              messageActionTypes.checklist.includes(chat.action)
            ) {
              const fieldId = Object.keys(chat.data?.fields ?? {})[0];
              type = getFieldTypeSelector(fieldId);
            }
            // Filter out messages unless it's an Approval field message
            return (
              (!messageFilter.users.includes(chat.author) &&
                !activeRecordFilters.includes(chat.action)) ||
              type === "approval"
            );
          })
        : data;
    return R.sortBy(R.prop("timestamp"))(filteredData);
  }
);

export const getChatroomMessageFilter = (state: RecentChats) =>
  state.filter.messages;

export const getMessageData = (state: AppState, messageId: string) =>
  state.recentChats.byId[messageId];

export const getMessageText = createSelector(getMessageData, message => {
  if (R.type(message.text) === "String") {
    return message.text.split("\n").join(" ");
  }

  // Handling old draftjs data
  const { blocks } = message.text || {};
  if (blocks) {
    return blocks[0].text.split("\n").join(" ");
  }
  return "";
});

export const getLastChat = createSelector(
  getRecentChats,
  chats => chats[chats.length - 1] || {}
);
